import { Button, Col, Form, Input, Layout, message, Row, Divider } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import API_MANAGER from '../../Api'
import { LoginStart, LoginSuccess, Logout } from '../../context/AuthActions'
import { AuthContext, AuthContextProvider } from '../../context/AuthContext'
import logo from '../../public/assets/auth/img/Logo.svg'
import Head from 'next/head'
import ReCAPTCHA from 'react-google-recaptcha'
// import { changeBg } from '../../utils/global'
import { GoogleLogin } from '@react-oauth/google'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { setCookie } from 'cookies-next'
import axios from 'axios'

const { Content } = Layout

const AdminLogin = () => {
  // window.Intercom('shutdown')

  const [messageApi, contextHolder] = message.useMessage()
  const { user, dispatch } = useContext(AuthContext)
  const router = useRouter()
  const [isVerified, setIsVerified] = useState(false)
  const recaptchaRef = React.useRef()
  const [ipAddress, setIpAddress] = useState(null)

  useEffect(() => {
    // Fetch the IP address using ipify API
    axios
      .get('https://api.ipify.org?format=json')
      .then((response) => {
        setIpAddress(response.data.ip)
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error)
      })
  }, [])

  const loginAdmin = async (values) => {
    const data = {
      ...values,
      platform_type: 'admin',
      ip_address: ipAddress ? ipAddress : '',
    }

    try {
      const response = await API_MANAGER.login(data)
      // console.log('---', response)

      dispatch(LoginStart({ email: data?.email }))
      router.push('/admin-verify-otp')
    } catch (error) {
      console.log('----error', error)
      error && message.error(error?.response?.data.message)
    }
  }

  const verifyCallback = async (response) => {
    if (response) {
      setIsVerified(true)
    }
  }

  const socialLogin = async (credentialResponse) => {
    try {
      console.log('---credss-', credentialResponse)
      const data = {
        token: credentialResponse?.credential,
        platform_type: 'admin',
        ip_address: ipAddress ? ipAddress : '',
      }

      // dispatch(studentGoogleLogin(data))

      console.log('---data---', data)

      const response = await API_MANAGER.socialLogin(data)
      let expireTime = new Date().getTime() + 3600 * 1000
      dispatch(LoginSuccess({ ...response.data.data, expireTime: expireTime }))

      setCookie('access_token', response?.data?.data?.access_token, {
        maxAge: 60 * 50,
        secure: true,
      })
      setCookie('refresh_token', response?.data?.data?.refresh_token, {
        maxAge: 60 * 60 * 24,
        secure: true,
      })

      router.push('/')
    } catch (error) {
      // console.log('err--', error)
      message.error(error?.response?.data.message)
    }
  }

  return (
    <>
      {contextHolder}
      <div className='auth-admin-section'>
        <div className='container auth-admin-container'>
          <div className='auth-admin-box my-5'>
            <div className='container'>
              <Col className=''>
                <Col span={24} className='logo-login'>
                  <Image src={logo} className='logomobile' alt='img' />
                </Col>
                <Row justify='center' className='pt-4'>
                  <Col style={{ color: '#242A32', fontSize: 20 }}>
                    Welcome !
                  </Col>
                </Row>

                <Row justify='center'>
                  <Col className='admin-auth-box-text mt-3 mb-5'>
                    Login to your Edly Admin account
                  </Col>
                </Row>

                <Form
                  className='auth-form'
                  onFinish={loginAdmin}
                  // onValuesChange={onFormChange}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'Please input your E-mail!',
                          },
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                        ]}
                      >
                        <Input
                          // prefix={<UserOutlined className="site-form-item-icon" />}
                          placeholder='Email ID'
                          type='email'
                          size='large'
                          name='email'
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name='password'
                        // hasFeedback
                        rules={[
                          { required: true, message: 'Please enter password' },
                        ]}
                      >
                        {/* <Input
                          prefix={<LockOutlined className="site-form-item-icon" />}
                          type="password"
                          placeholder="Password"
                          size="large"
                        /> */}
                        <Input.Password placeholder='Password' size='large' />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Form.Item className='forgot-password-item'>
                        <Link
                          className='forgot-password'
                          href='/admin-forgot-password'
                        >
                          Forgot Password
                        </Link>
                      </Form.Item>
                    </Col>
                  </Row>

                  {!isVerified &&
                  process.env.NEXT_PUBLIC_ENV == 'production' ? (
                    <Row justify={'center'} id='capture'>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size='normal'
                        sitekey={process.env.NEXT_PUBLIC_STUDENT_RECAPTCHA}
                        onChange={verifyCallback}
                        className='google-recaptcha'
                        onExpired={() => setIsVerified(false)}
                      />
                    </Row>
                  ) : (
                    <Form.Item className='admin-auth-form-button'>
                      <Button type='primary' htmlType='submit' size='large'>
                        Log in
                      </Button>
                    </Form.Item>
                  )}

                  <Divider className='or-divider'>or</Divider>
                  <Row
                    className='google-login-row'
                    justify='center'
                    align='middle'
                  >
                    <Col span={24} className='google-login-col'>
                      <Row justify='center'>
                        <GoogleLogin
                          onSuccess={socialLogin}
                          onError={() => {
                            message.error('Login Failed')
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminLogin

AdminLogin.getLayout = function getLayout(page) {
  return (
    <>
      <AuthContextProvider>
        <GoogleOAuthProvider clientId='299899089685-8o2bvmesjcgm9nv60sqlf5fuuh34t1ou.apps.googleusercontent.com'>
          <Head>
            <title>Edly</title>
            <meta
              name='description'
              content="Edly allows investors to invest in the education of students and earn a percentage of the students' future income."
            />
            <link
              href='https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,300,800,900'
              rel='stylesheet'
              type='text/css'
            />
            <link rel='icon' href='/Logo.svg' />
          </Head>
          {page}
        </GoogleOAuthProvider>
      </AuthContextProvider>
    </>
  )
}
